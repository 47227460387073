var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('tooltip-position'), _c('tooltip-variant'), _c('tooltip-trigger'), _c('tooltip-option'), _c('tooltip-method'), _c('tooltip-event')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }